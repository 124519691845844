import { createTheme } from "@mui/material/styles";

const CustomTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#2b4570",
      light: "rgb(85, 106, 140)",
      dark: "rgb(30, 48, 78)",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#a8d0db",
      light: "rgb(247, 51, 120)",
      dark: "rgb(171, 0, 60)",
      contrastText: "#ffffff"
    },
    primaryLight: {
      main: "#a8d0db",
      light: "rgb(185, 217, 226)",
      dark: "rgb(117, 145, 153)",
      contrastText: "#ffffff"
    },
    secondaryLight: {
      main: "#e49273",
      light: "#F3CFC2",
      contrastText: "#eaeaea"
    },

    text: {
      primary: "#0F0F0F", //Dark Text
      secondary: "#6a6a6a" //Light Text
    }
  },
  typography: {
    button: {
      textTransform: "none"
    },
    fontFamily: ["Nunito", "sans-serif"].join(","),
    h1: {
      fontSize: "4.5rem",
      fontWeight: 700
    },
    h2: {
      fontSize: "2.75rem",
      fontWeight: 700
    },
    h3: {
      fontSize: "1.9rem",
      fontWeight: 700
    },
    h4: {
      fontSize: "1.625rem",
      fontWeight: 600
    },
    h5: {
      fontSize: "1.525rem",
      fontWeight: 600
    },
    h6: {
      fontSize: "1.2rem",
      fontWeight: 500
    }
  }
});

export default CustomTheme;
