import axios from "axios";

const ApiEndPoint = `${process.env.REACT_APP_APIENDPOINT}`;
// const ApiEndPoint = `http://localhost:3000`;

export const postSubscriptionLogin = async (payload) => {

  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/subscription/login`,
    payload
  );
  return responseBody ? responseBody : {};
};

export const postSubscriptionOtp = async (payload) => {

  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/subscription/verify-otp`,
    payload
  );
  return responseBody ? responseBody : {};
};

export const getUserSubscription = async (token) => {

  const { data: responseBody } = await axios.get(
    `${ApiEndPoint}/subscription/userDetails`,
    { headers: { Authorization: `${token}` } }
  );
  return responseBody ? responseBody : {};
};
export const updatePaymentMethodsSubscription = async (token, payload) => {

  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/subscription/update-paymentMethod`,
    payload,
    { headers: { Authorization: `${token}` } }
  );
  return responseBody ? responseBody : {};
};

export const updateSubscription = async (token, payload) => {
 
  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/subscription/update-subscription`,
    payload,
    { headers: { Authorization: `${token}` } }
  );
  return responseBody ? responseBody : {};
};
export const updateSubscriptionLicensePlate = async (token, payload) => {

  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/subscription/update-licensePlate`,
    payload,
    { headers: { Authorization: `${token}` } }
  );
  return responseBody ? responseBody : {};
};
export const updateUnpaidSubscription = async (payload) => {
 
  const { data: responseBody } = await axios.post(
    `${ApiEndPoint}/portal/renew-monthly-subscription-strip`,
    payload,
  );
  return responseBody ? responseBody : {};
};
